import React from "react";
import { isMobile } from "react-device-detect";
import { Menu, Icon } from "semantic-ui-react";
import VkGroup from "./VkGroup";
/* global VK */

class VkLogin extends React.Component {
  componentDidMount() {

  }

  render() {
    return (
      <div>
      
          <div style={{fontSize: "18px"}}>
            
            В перспективе здесь будет список созданных проектов&nbsp;:)
            <br />
            <br />
            
          </div>
        
      </div>
    );
  }
}

export default VkLogin;
